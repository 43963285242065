<template>
  <v-card v-if="apiLoaded">
    <v-card-title>
      <span class="text-h5">Экспорт данных паспортов регионов</span>
      <a class="close" @click="$emit('close')">
        <svg
          fill="none"
          height="24"
          viewBox="0 0 24 24"
          width="24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
            fill="#0033A0"
          />
        </svg>
      </a>
    </v-card-title>
    <v-card-text>
      <div class="rating-popup">
        <v-container>
          <v-row>
            <v-col cols="12">
              <h4>Фильтры</h4>
            </v-col>
            <v-col cols="12">
              <v-select
                :items="getConfigIds()"
                v-model="configId"
                label="Конфигурация"
                @change="configIfChanged()"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-select
                :items="regions"
                item-text="name"
                item-value="code"
                label="Регион"
                v-model="filterRegion"
              ></v-select>
            </v-col>

            <v-col cols="12">
              <v-select
                :items="config.sections"
                item-text="name"
                item-value="id"
                label="Раздел"
                v-model="filterSection"
              ></v-select>
            </v-col>

            <v-col cols="12">
              <v-select
                :items="sortFieldOptions"
                label="Поле для сортировки"
                v-model="sortField"
              ></v-select>
            </v-col>

            <v-col cols="12">
              <h4>Выберите формат</h4>
            </v-col>

            <v-col cols="12">
              <v-radio-group v-model="format" row>
                <v-radio label="XLSX" value="xlsx"></v-radio>
                <v-radio label="PDF" value="pdf"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-card-text>
    <v-card-actions style="margin-left: 32%">
      <v-btn outlined @click="$emit('close')"> отмена </v-btn>
      <v-btn color="primary" @click="submit()"> Экспорт </v-btn>
      <div
        v-if="errorText != null"
        class="error"
        style="position: fixed; bottom: 0"
      >
        Ошибка: {{ errorText }}
      </div>
    </v-card-actions>
    <LoaderDialog :show="showLoaderDialog"></LoaderDialog>
  </v-card>
</template>

<script>
import LoaderDialog from "../elements/LoaderDialog";

export default {
  name: "MonitoringExportDialog",
  components: { LoaderDialog },
  data: () => ({
    config: null,
    configId: null,
    values: null,
    regions: null,
    filterRegion: null,
    filterSection: null,
    sortField: null,
    showLoaderDialog: false,
    sortFieldOptions: [
      { value: "region", text: "Регион" },
      { value: "sectionId", text: "Раздел" },
      { value: "indicatorId", text: "Показатель" },
      { value: "year", text: "Год" },
      { value: "value", text: "Значение" },
    ],
    format: "xlsx",
    errorText: null,
    apiLoaded: false,
  }),
  methods: {
    // async loadConfig() {
    //   let req = await this.$getApi("/passport/getActiveConfig")
    //   if (req.ok) {
    //     this.config = req.payload
    //     this.config.sections = JSON.parse(this.config.sectionsJson)
    //   } else {
    //     this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
    //   }
    // },

    async loadConfigs() {
      let req = await this.$getApi("/passport/getConfigsList");
      if (req.ok) {
        this.configs = req.payload;
        this.sortConfigsById();
      } else {
        this.errorText =
          "Ошибка подключения к серверу или обработки запроса: " + req.error;
      }
    },

    sortConfigsById() {
      this.configs.sort((a, b) => (a.id > b.id ? 1 : -1));
    },

    sortValues(field) {
      if (field == null) return;
      this.values.sort((a, b) => (a[field] > b[field] ? 1 : -1));
    },

    async loadValues() {
      let req = await this.$getApi("/passport/getFilteredValues", {
        configId: 7,
      });
      if (req.ok) {
        this.values = req.payload;
        this.config.sections = JSON.parse(this.config.sectionsJson);
      } else {
        this.errorText =
          "Ошибка подключения к серверу или обработки запроса: " + req.error;
      }
    },

    async loadRegions() {
      let req = await this.$getApi("/getRegionsList", {});
      if (req.ok) {
        this.regions = req.payload;
      } else {
        this.errorText =
          "Ошибка подключения к серверу или обработки запроса: " + req.error;
      }
    },

    findRegionByCode(code) {
      for (let r of this.regions) {
        if (r.code === code) return r;
      }
    },

    getSection(sectionId) {
      for (let s of this.config.sections) if (s.id === sectionId) return s;
    },

    getIndicator(section, indicatorId) {
      for (let i of section.indicators) if (i.id === indicatorId) return i;
    },

    getSubIndicator(indicator, subIndicatorId) {
      for (let si of indicator.subIndicators)
        if (si.id === subIndicatorId) return si;
    },

    async submit() {
      this.showLoaderDialog = true;
      let query = {
        headerCells: [
          {
            caption: "Ид конфигурации",
            width: 5000,
          },
          {
            caption: "Регион",
            width: 5000,
          },
          {
            caption: "Ид раздела",
            width: 2000,
          },
          {
            caption: "Раздел",
            width: 10000,
          },
          {
            caption: "Ид показателя",
            width: 2000,
          },
          {
            caption: "Показатель",
            width: 10000,
          },
          {
            caption: "Ид подпоказателя",
            width: 2000,
          },
          {
            caption: "Подпоказатель",
            width: 10000,
          },
          {
            caption: "Год",
            width: 4000,
          },
          {
            caption: "Значение",
            width: 20000,
          },
        ],
        rows: [],
      };
      await this.loadValues();

      this.sortValues(this.sortField);
      let filteredValues = this.values.filter((v) => {
        if (this.configId !== v.configId) return false;
        if (this.filterRegion != null && v.region !== this.filterRegion)
          return false;
        if (this.filterSection != null && v.sectionId !== this.filterSection)
          return false;
        return true;
      });
      for (let v of filteredValues) {
        let section = this.getSection(v.sectionId);
        let indicator = this.getIndicator(section, v.indicatorId);
        let subIndicator;
        if (v.subIndicatorId != null)
          subIndicator = this.getSubIndicator(indicator, v.subIndicatorId);
        query.rows.push([
          { cellDataType: "STRING", value: v.configId },
          {
            cellDataType: "STRING",
            value: this.findRegionByCode(v.region).name,
          },
          { cellDataType: "INTEGER", value: v.sectionId },
          { cellDataType: "STRING", value: section.name },
          { cellDataType: "INTEGER", value: v.indicatorId },
          { cellDataType: "STRING", value: indicator.name },
          {
            cellDataType: "INTEGER",
            value: v.subIndicatorId ? v.subIndicatorId : "",
          },
          {
            cellDataType: "STRING",
            value: v.subIndicatorId ? subIndicator.name : "",
          },
          { cellDataType: "STRING", value: v.year },
          { cellDataType: "STRING", value: v.value },
        ]);
      }
      //console.log(query)
      await this.$downloadApi("ИС_Мониторинга_СЗН_Паспорта."+this.format.toLowerCase(),"/export/" + this.format, query, true);
      this.showLoaderDialog = false;
      this.$emit("close");
    },

    getActiveConfig() {
      for (let c of this.configs) {
        if (c.active) return c;
      }
    },

    getConfigById(id) {
      for (let c of this.configs) {
        if (c.id === id) return c;
      }
    },

    getConfigIds() {
      return this.configs.map((v) => {
        return {
          value: v.id,
          text: v.active ? "Действующая (id " + v.id + ")" : "id " + v.id,
        };
      });
    },

    configIfChanged() {
      this.config = this.getConfigById(this.configId);
      this.config.sections = JSON.parse(
        this.getConfigById(this.configId).sectionsJson
      );
    },

    // async submit(){
    //   await this.$downloadApi("/passport/export")
    //   this.$emit('close')
    // }
  },
  async beforeMount() {
    let promise1 = this.loadRegions();
    await this.loadConfigs();
    await promise1;
    this.config = this.getActiveConfig();
    this.config.sections = JSON.parse(this.getActiveConfig().sectionsJson);
    // console.log(this.config);
    this.configId = this.config.id;
    this.apiLoaded = true;
  },
};
</script>
