<template>
  <v-card v-if="apiLoaded">
    <v-card-title>
      <span class="text-h5"
        >Экспорт данных мониторинга информационных мероприятий</span
      >
      <a class="close" @click="$emit('close')">
        <svg
          fill="none"
          height="24"
          viewBox="0 0 24 24"
          width="24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
            fill="#0033A0"
          />
        </svg>
      </a>
    </v-card-title>
    <v-card-text>
      <div class="rating-popup">
        <v-container>
          <v-row>
            <v-col cols="12">
              <h4>Фильтры</h4>
            </v-col>

            <v-col cols="12">
              <v-select
                :items="getConfigIds()"
                v-model="configId"
                label="Конфигурация"
                @change="configIfChanged()"
              ></v-select>
            </v-col>

            <v-col cols="12">
              <v-select
                :items="$cznDepartmentsList"
                item-text="fullName"
                item-value="userName"
                label="ЦЗН"
                @change="pickedUserName = $event"
              ></v-select>
            </v-col>

            <v-col cols="12">
              <v-select
                :items="config.directions"
                item-text="name"
                item-value="id"
                label="Направление"
                @change="pickedDirectionId = $event"
              ></v-select>
            </v-col>

            <v-col cols="12">
              <v-select
                v-if="pickedDirectionId"
                :items="getDirection(pickedDirectionId).actions"
                item-text="name"
                item-value="id"
                label="Мероприятие"
                @change="pickedActionId = $event"
              ></v-select>
            </v-col>

            <v-col cols="12">
              <v-select
                :items="sortFieldOptions"
                label="Поле для сортировки"
                v-model="sortField"
              ></v-select>
            </v-col>

            <v-col cols="12">
              <h4>Выберите формат</h4>
            </v-col>

            <v-col cols="12">
              <v-radio-group v-model="format" row>
                <v-radio label="XLSX" value="xlsx"></v-radio>
                <v-radio label="PDF" value="pdf"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-card-text>
    <v-card-actions style="margin-left: 32%">
      <v-btn outlined @click="$emit('close')"> отмена </v-btn>
      <v-btn color="primary" @click="submit()"> Экспорт </v-btn>
      <div
        v-if="errorText != null"
        class="error"
        style="position: fixed; bottom: 0"
      >
        Ошибка: {{ errorText }}
      </div>
    </v-card-actions>
    <LoaderDialog :show="showLoaderDialog"></LoaderDialog>
  </v-card>
</template>

<script>
import LoaderDialog from "../elements/LoaderDialog";

export default {
  name: "InfeventsExportDialog",
  components: { LoaderDialog },

  data: () => ({
    config: null,
    configId: null,
    pickedUserName: null,
    pickedDirectionId: null,
    pickedActionId: null,
    sortField: null,
    sortFieldOptions: [
      { value: "userName", text: "ЦЗН" },
      {
        value: "endDate",
        text: "Дата завершения",
      },
      { value: "scale", text: "Охват" },
    ],
    format: "xlsx",
    showLoaderDialog: false,
    errorText: null,
    apiLoaded: false,
  }),
  methods: {
    async loadValues() {
      let req = await this.$getApi(
        "/infevents/getAllStageValues",
        this.querySpec
      );
      if (req.ok) {
        this.values = req.payload;
      } else {
        this.errorText =
          "Ошибка подключения к серверу или обработки запроса: " + req.error;
      }
    },

    sortValues(field) {
      if (field == null) return;
      this.values.sort((a, b) => (a[field] > b[field] ? 1 : -1));
    },

    async loadConfigs() {
      let req = await this.$getApi("/infevents/getConfigsList");
      if (req.ok) {
        this.configs = req.payload;
        this.sortConfigsById();
      } else {
        this.errorText =
          "Ошибка подключения к серверу или обработки запроса: " + req.error;
      }
    },

    getActiveConfig() {
      for (let c of this.configs) {
        if (c.active) return c;
      }
    },

    sortConfigsById() {
      this.configs.sort((a, b) => (a.id > b.id ? 1 : -1));
    },

    getConfigIds() {
      return this.configs.map((v) => {
        return {
          value: v.id,
          text: v.active ? "Действующая (id " + v.id + ")" : "id " + v.id,
        };
      });
    },

    configIfChanged() {
      this.config = this.getConfigById(this.configId);
      this.config.directions = JSON.parse(
        this.getConfigById(this.configId).directions
      );
    },

    getDirection(directionId) {
      for (let direction of this.config.directions) {
        if (directionId === direction.id) return direction;
      }
      return null;
    },

    getAction(directionId, actionId) {
      let direction = this.getDirection(directionId);
      if (direction == null) return null;
      for (let action of direction.actions) {
        if (action.id === actionId) return action;
      }
      return null;
    },

    async submit() {
      // console.log({
      //   configId: this.config.id,
      //   userName: this.pickedUserName,
      //   directionId: this.pickedDirectionId,
      //   actionId: this.pickedActionId,
      //   format: this.format,
      // });
      this.showLoaderDialog = true;
      let query = {
        headerCells: [
          {
            caption: "Ид конфигурации",
            width: 5000,
          },
          {
            caption: "ЦЗН",
            width: 5000,
          },
          {
            caption: "Ид направления",
            width: 2000,
          },
          {
            caption: "Направление",
            width: 10000,
          },
          {
            caption: "Ид мероприятия",
            width: 2000,
          },
          {
            caption: "Мероприятие",
            width: 10000,
          },
          {
            caption: "Дата завершения",
            width: 5000,
          },
          {
            caption: "Охват",
            width: 5000,
          },
          {
            caption: "Комментарий",
            width: 5000,
          },
        ],
        rows: [],
      };

      await this.loadValues();

      this.sortValues(this.sortField);
      let filteredValues = this.values.filter((v) => {
        if (this.configId !== v.configId) return false;
        if (
          this.pickedUserName != null &&
          this.pickedUserName != "" &&
          v.userName !== this.pickedUserName
        )
          return false;
        if (
          this.pickedDirectionId != null &&
          this.pickedDirectionId != "" &&
          v.directionId !== this.pickedDirectionId
        )
          return false;
        if (
          this.pickedActionId != null &&
          this.pickedActionId != "" &&
          v.actionId !== this.pickedActionId
        )
          return false;

        return true;
      });
      for (let v of filteredValues) {
        let direction = this.getDirection(v.directionId);
        let action = this.getAction(v.directionId, v.actionId);
        query.rows.push([
          { cellDataType: "STRING", value: v.configId },
          {
            cellDataType: "STRING",
            value: this.$getUserByName(v.userName).name,
          },
          { cellDataType: "INTEGER", value: v.directionId },
          { cellDataType: "STRING", value: direction.name },
          { cellDataType: "INTEGER", value: v.actionId },
          { cellDataType: "STRING", value: action.name },
          { cellDataType: "STRING", value: this.formatDate(v.endDate) },
          { cellDataType: "STRING", value: v.scale },
          { cellDataType: "STRING", value: v.comment },
        ]);
      }
      //console.log(query)
      await this.$downloadApi("ИС_Мониторинга_СЗН_Инфмероприятия."+this.format.toLowerCase(),"/export/" + this.format, query, true);
      this.showLoaderDialog = false;
      this.$emit("close");
    },

    formatDate(date) {
      if (date == null) {
        return "";
      }
      let mdate = new Date(date);
      let options = {
        timeZone: "Europe/Moscow",
        year: "numeric",
        month: "numeric",
        day: "numeric",
      };
      return mdate.toLocaleDateString("ru-RU", options);
    },
  },
  async beforeMount() {
    await this.loadConfigs();
    this.config = this.getActiveConfig();
    this.config.directions = JSON.parse(this.getActiveConfig().directionsJson);
    this.configId = this.config.id;
    this.apiLoaded = true;
  },
};
</script>
