import api from "./api"
import {restoreAnswers} from "./questions";

const AnketService = {
    async loadAllAnkets(err) {
        let req = await api.get("/ankets/getAllAnkets", {})
        if (req.ok) {
            return req.payload
        } else {
            err("Ошибка подключения к серверу или обработки запроса: loadAnketFormData " + req.error)
        }
    },

    async loadAnketsByConfig (configId, err) {
        let req = await api.get("/ankets/getAnketsByConfigId", {configId: configId})
        if (req.ok) {
            return req.payload
        } else {
            err("Ошибка подключения к серверу или обработки запроса: loadAnketConfigs " + req.error)
        }
    },

    async loadAnketConfigs(err) {
        let req = await api.get("/ankets/getAllConfigs", {})
        if (req.ok) {
            for (let c of req.payload) {
                c.anket = JSON.parse(c.anketJson)
            }
            return req.payload
        } else {
            err("Ошибка подключения к серверу или обработки запроса: loadAnketConfigs " + req.error)
        }
    },

    getConfigById(configs, id) {
        for (let c of configs) {
            if (c.id === id)
                return c
        }
    },

    getReadableAnswer(question){
        if(question.answer == null && question.answer === "")
            return "";
        if(question.multiple){
            let s = "";
            for(let a of question.answer){
                s += question.options[a].text + "; ";
            }
            return s;
        } else {
            return question.options[question.answer].text
        }
    },

    formAnswersReadableString(config, anketFormData) {
        // console.log(JSON.parse(config.anketJson))
        let answeredConfig = restoreAnswers(JSON.parse(config.anketJson), anketFormData.answers);
        let s = ""
        let stepNum = 0
        for(let step of answeredConfig.anket.steps){
            stepNum++
            s += "\nШаг " + stepNum
            for(let q of step.questions){
                s += "\n"+q.text + ": " + this.getReadableAnswer(q);
            }
        }
        return s
    }
}

export default AnketService
