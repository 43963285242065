<template>
  <v-card v-if="apiLoaded">
    <v-card-title>
      <span class="text-h5">Экспорт данных подсистемы анкетирования</span>
      <a class="close" @click="$emit('close')">
        <svg
            fill="none"
            height="24"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
        >
          <path
              d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
              fill="#0033A0"
          />
        </svg>
      </a>
    </v-card-title>
    <v-card-text>
      <div class="rating-popup">
        <v-container>
          <v-row>
            <v-col cols="12">
              <h4>Фильтры</h4>
            </v-col>

            <v-col cols="12">
              <v-select
                  :items="getConfigIds()"
                  v-model="configId"
                  label="Конфигурация"
              ></v-select>
            </v-col>

<!--            <v-col cols="12">-->
<!--              <v-select-->
<!--                  :items="$cznDepartmentsList"-->
<!--                  v-model="filterCzn"-->
<!--                  item-text="fullName"-->
<!--                  item-value="userName"-->
<!--                  label="ЦЗН"-->
<!--              ></v-select>-->
<!--            </v-col>-->

            <v-col cols="12">
              <v-autocomplete
                  label="Регион ЦЗН"
                  :items="$cznDepartmentsByRegionList"
                  item-text="name"
                  item-value="departments"
                  v-model="departmentsList"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12">
              <v-autocomplete
                  label="ЦЗН"
                  :items="departmentsList"
                  item-text="name"
                  item-value="id"
                  v-model="filterCzn"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12">
              <v-select
                  :items="sortFieldOptions"
                  label="Поле для сортировки"
                  v-model="sortField"
              ></v-select>
            </v-col>

            <v-col cols="12">
              <h4>Выберите формат</h4>
            </v-col>

            <v-col cols="12">
              <v-radio-group v-model="format" row>
                <v-radio label="XLSX" value="xlsx"></v-radio>
                <v-radio label="PDF" value="pdf"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-card-text>
    <v-card-actions style="margin-left: 32%">
      <v-btn outlined @click="$emit('close')"> отмена</v-btn>
      <v-btn color="primary" @click="submit()"> Экспорт</v-btn>
      <div
          v-if="errorText != null"
          class="error"
          style="position: fixed; bottom: 0"
      >
        Ошибка: {{ errorText }}
      </div>
    </v-card-actions>
    <LoaderDialog :show="showLoaderDialog"></LoaderDialog>
  </v-card>
</template>

<script>
import AnketService from "../../modules/ankets";
import LoaderDialog from "../elements/LoaderDialog";
import {getExcelNewAnswersStr, getQuestionNames} from "../../modules/questions";

export default {
  name: "AnketsExportDialog",
  components: {LoaderDialog},
  data: () => ({
    config: null,
    configId: null,
    showLoaderDialog: false,
    departmentsList: null,
    department: null,
    sortField: null,
    sortFieldOptions: [
      {value: "cznName", text: "ЦЗН"},
      {value: "regnumber", text: "Регномер"},
      {value: "email", text: "Email"},
      {value: "localDateTime", text: "Дата заполнения"},
      {value: "sessionResult", text: "Результат приема"},
    ],
    format: "xlsx",
    sessionResults: [
      ["INFO", "Информирование"],
      ["PENS", "Для пенсионеров – досрочная пенсия"],
      ["PUBL", "Общественные работы"],
      ["ORIENT", "Профессиональная ориентация"],
      ["EDU", "Профессиональное обучение"],
      ["PROB", "Профессиональные пробы"],
      ["SUPP", "Психологическая поддержка"],
      ["SELF", "Самозанятость"],
      ["ADAPT", "Социальная адаптация"],
      ["SPEC", "Специализированные программы"],
      ["TRAIN", "Стажировка"],
      ["JOB", "Трудоустройство"],
      ["MARKT", "Ярмарка вакансий"],
    ],
    filterCzn: null,
    errorText: null,
    apiLoaded: false,
  }),
  methods: {
    async submit() {
      this.showLoaderDialog = true;
      let query = {
        headerCells: [
          {
            caption: "Ид",
            width: 5000,
          },
          {
            caption: "Ид конфигурации",
            width: 5000,
          },
          {
            caption: "ЦЗН",
            width: 10000,
          },
          {
            caption: "Регномер",
            width: 5000,
          },
          {
            caption: "Email",
            width: 7000,
          },
          {
            caption: "Дата заполнения",
            width: 7000,
          }
        ],
        rows: [],
      };

      let values = await AnketService.loadAnketsByConfig(this.configId,
          (err) => (this.errorText = err)
      );

      values = this.sortValues(values, this.sortField);
      let filteredValues = values.filter((v) => {
        if (this.filterCzn != null && this.filterCzn != "" && v.cznName != this.filterCzn) //Тут последнее выражение "И" было "v.cznName !== this.filterCzn"
          return false
        return true;
      });

      let activeConfig = AnketService.getConfigById(this.configs, this.configId)
      query.headerCells = query.headerCells.concat(getQuestionNames(activeConfig.anket).map((v) => {
        return {caption: v, width: 4000}
      }))

      for (let v of filteredValues) {
        let department = await this.$getDepartmentById(v.cznName)
        let row = [
          {cellDataType: "INTEGER", value: v.id},
          {cellDataType: "INTEGER", value: v.configId + " " + activeConfig.anket.title},
          {
            cellDataType: "STRING",
            value: department ? department.name : ""
          },
          {cellDataType: "STRING", value: v.regnumber},
          {cellDataType: "STRING", value: v.email},
          {
            cellDataType: "STRING",
            value: this.formatDateTime(v.localDateTime),
          }
        ]
        let func = getExcelNewAnswersStr// v.id > 100 ? getExcelNewAnswersStr : getExcelAnswersStr
        row = row.concat(func(activeConfig.anket, v.answers).map((v) => {
          return {cellDataType: Number.isInteger(+v) ? "INTEGER" : "STRING", value: v}
        }))
        query.rows.push(row);
      }
      await this.$downloadApi("ИС_Мониторинга_СЗН_Анкеты." + this.format.toLowerCase(), "/export/" + this.format, query, true);
      this.showLoaderDialog = false;
      this.$emit("close");
    },

    sortValues(values, field) {
      if (field == null) return values;
      values.sort((a, b) => (a[field] > b[field] ? 1 : -1));
      return values;
    },

    getSessionResult(code) {
      for (let entry of this.sessionResults) {
        if (entry[0] === code) return entry[1];
      }
    },

    formatDateTime(date) {
      if (date == null) {
        return "";
      }
      let mdate = new Date(date);
      let options = {
        timeZone: "Europe/Moscow",
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      };
      return mdate.toLocaleDateString("ru-RU", options);
    },

    getConfigIds() {
      return this.configs.map((v) => {
        return {
          value: v.id,
          text: "id " + v.id + "(" + v.anket.title + ")",
        };
      });
    },
  },
  async beforeMount() {
    this.configs = await AnketService.loadAnketConfigs(
        (err) => (this.errorText = err)
    );
    this.configId = this.configs[0].id
    this.apiLoaded = true;
  },
};
</script>
