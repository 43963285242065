<template>
  <v-card v-if="apiLoaded">
    <v-card-title>
      <span class="text-h5">Экспорт данных подсистемы ЖС/БС</span>
      <a class="close" @click="$emit('close')">
        <svg
          fill="none"
          height="24"
          viewBox="0 0 24 24"
          width="24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
            fill="#0033A0"
          />
        </svg>
      </a>
    </v-card-title>
    <v-card-text>
      <div class="rating-popup">
        <v-container>
          <v-row>
            <v-col cols="12">
              <h4>Фильтры</h4>
            </v-col>
            <v-col cols="12">
              <v-select
                :items="$cznDepartmentsList"
                item-text="fullName"
                item-value="userName"
                label="ЦЗН"
                v-model="filterCzn"
              ></v-select>
            </v-col>

            <v-col cols="12">
              <v-select
                :items="stutusFilterOptions"
                label="Статус"
                v-model="filterStatus"
              ></v-select>
            </v-col>

            <v-col cols="12">
              <v-select
                :items="sortFieldOptions"
                label="Поле для сортировки"
                v-model="sortField"
              ></v-select>
            </v-col>

            <v-col cols="12">
              <h4>Выберите формат</h4>
            </v-col>

            <v-col cols="12">
              <v-radio-group v-model="format" row>
                <v-radio label="XLSX" value="xlsx"></v-radio>
                <v-radio label="PDF" value="pdf"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-card-text>
    <v-card-actions style="margin-left: 32%">
      <v-btn outlined @click="$emit('close')"> отмена </v-btn>
      <v-btn color="primary" @click="submit()"> Экспорт </v-btn>
      <div
        v-if="errorText != null"
        class="error"
        style="position: fixed; bottom: 0"
      >
        Ошибка: {{ errorText }}
      </div>
    </v-card-actions>
    <LoaderDialog :show="showLoaderDialog"></LoaderDialog>
  </v-card>
</template>

<script>
import LoaderDialog from "../elements/LoaderDialog";

export default {
  name: "SituationExportDialog",
  components: { LoaderDialog },
  data: () => ({
    filterCzn: null,
    filterStatus: null,
    sortField: null,
    sortFieldOptions: [
      { value: "userName", text: "ЦЗН" },
      {
        value: "cznSystemNumber",
        text: "Номер получателя услуг в системе ЦЗН",
      },
      { value: "status", text: "Статус" },
      { value: "specialistFullName", text: "Инициалы специалиста" },
      { value: "requestDate", text: "Дата запроса" },
      { value: "openingDate", text: "Дата открытия карточки" },
      { value: "changedDate", text: "Дата изменения карточки" },
    ],
    stutusFilterOptions: [
      { value: "LIVE_SITUATION", text: "Жизненная ситуация" },
      { value: "BUSINESS_SITUATION", text: "Бизнес-ситуация" },
    ],
    format: "xlsx",
    showLoaderDialog: false,
    errorText: null,
    apiLoaded: false,
  }),
  methods: {
    async loadValues() {
      let req = await this.$getApi(
        "/situations/getAllSituationValues",
        this.querySpec
      );
      if (req.ok) {
        this.situationValues = req.payload;
        // console.log(this.situationValues);
      } else {
        this.errorText =
          "Ошибка подключения к серверу или обработки запроса: " + req.error;
      }
    },

    sortValues(values, field) {
      if (field == null) return values;
      values.sort((a, b) => (a[field] > b[field] ? 1 : -1));
      return values;
    },

    async submit() {
      this.showLoaderDialog = true;
      let query = {
        headerCells: [
          {
            caption: "Ид",
            width: 5000,
          },
          {
            caption: "Тип сиутации",
            width: 5000,
          },
          {
            caption: "Ситуация",
            width: 5000,
          },
          {
            caption: "ЦЗН",
            width: 7000,
          },
          {
            caption: "Номер получателя услуг в системе ЦЗН",
            width: 7000,
          },
          {
            caption: "Статус",
            width: 7000,
          },
          {
            caption: "Инициалы специалиста",
            width: 7000,
          },
          {
            caption: "Дата запроса",
            width: 8000,
          },
          {
            caption: "Дата открытия карточки",
            width: 8000,
          },
          {
            caption: "Дата изменения карточки",
            width: 8000,
          },
        ],
        rows: [],
      };
      // console.log("deb");
      // console.log(this.situationValues);
      let values = this.sortValues(this.situationValues, this.sortField);
      let filteredValues = values.filter((v) => {
        if (
          this.filterCzn != null &&
          this.filterCzn != "" &&
          v.userName !== this.filterCzn
        )
          return false;
        if (
          this.filterStatus != null &&
          this.filterStatus != "" &&
          v.situationInDict.situationType !== this.filterStatus
        )
          return false;
        return true;
      });

      for (let v of filteredValues) {
        query.rows.push([
          { cellDataType: "INTEGER", value: v.id },
          {
            cellDataType: "STRING",
            value:
              v.situationInDict.situationType === "LIVE_SITUATION"
                ? "Жизненная ситуация"
                : "Бизнес-ситуация",
          },
          { cellDataType: "STRING", value: v.situationInDict.situationName },
          {
            cellDataType: "STRING",
            value: this.$getUserByName(v.userName).name,
          },
          { cellDataType: "STRING", value: v.cznSystemNumber },

          {
            cellDataType: "STRING",
            value: v.status === "IN_PROGRESS" ? "В процессе" : "Завершено",
          },
          { cellDataType: "STRING", value: v.specialistFullName },
          { cellDataType: "STRING", value: this.formatDate(v.requestDate) },
          { cellDataType: "STRING", value: this.formatDate(v.openingDate) },
          { cellDataType: "STRING", value: this.formatDate(v.changedDate) },
        ]);
      }

      await this.$downloadApi("ИС_Мониторинга_СЗН_ЖСБС."+this.format.toLowerCase(),"/export/" + this.format, query, true);
      this.showLoaderDialog = false;
      this.$emit("close");
    },

    formatDate(date) {
      if (date == null) {
        return "";
      }
      let mdate = new Date(date);
      let options = {
        timeZone: "Europe/Moscow",
        year: "numeric",
        month: "numeric",
        day: "numeric",
      };
      return mdate.toLocaleDateString("ru-RU", options);
    },
  },
  async beforeMount() {
    await this.loadValues();
    this.apiLoaded = true;
  },
};
</script>
